@import "@/assets/scss/style.scss";

.chat-input-area-wrapper {
    flex: 1 0 auto;
    background-color: $white-two;
    padding: 14px;
    border-top: 1px solid $grey-4;

    &.is-form-active {
        &::v-deep {
            .c-single-next-step-form {
                padding: 0;
                border: none;
            }
        }
    }
}

.bottom-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &--single {
        justify-content: flex-end;
    }
}
