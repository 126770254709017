@import "@/assets/scss/style.scss";


.wrapper {
    height: 100vh;
}

.innerWrapper {
    height: 100%;
}

.ant-layout-content {
    overflow: hidden;
    border-left: 1px solid $grey-4;
}

.sider {
    background-color: $white-two;
}

