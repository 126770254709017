@import "@/assets/scss/style.scss";

.wrapper {
    padding: 8px;
    border-radius: 4px;
    background-color: $white-two;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &.is-selected {
        background-color: #e2f8ff;
    }
}

.topRow {
    color: $grey-9;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
}

.bottomRow {
    color: $grey-7;
    word-break: break-all;
}

.latestMessage {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 4px;
}

.bottomRow {
    margin-left: 6px;
}
