@import "@/assets/scss/style.scss";

section {
    padding: 16px 16px 0;
}

.sidebar-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.loading-icon {
    font-size: 24px;
    color: $black;
    margin-left: 6px;
}

.ant-pagination {
    padding: 8px;
    // border-top: 1px solid $grey-4;

    &::v-deep {
        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next {
            height: 24px;
            min-width: 24px;
            //min-width: initial;
            line-height: 22px;
            margin-right: 6px;

            a {
                padding: 0 2px;
                font-size: 12px;
            }
        }
    }
}

.ant-tabs {
    flex: 1 0 auto;

    &::v-deep {
        .ant-tabs-bar {
            margin-bottom: 0;
        }
    }
}
