@import "@/assets/scss/style.scss";

.c-single-next-step-form {
    padding: 12px 16px 16px;
    border-radius: 5px;
    margin-bottom: 24px;
    border: 1px solid $grey-1;

    &.is-showing-only-one-item {
        padding: 12px 0 16px;
        border: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__text-button {
        font-weight: bold !important;
    }

    &__delete-button.ant-btn.ant-btn-link {
        color: #595959;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: #f5222d;
        }
    }

    &__title {
        margin-bottom: 16px;
        min-height: 32px;
    }

    &__top {
        display: flex;
        align-content: center;
    }

    &__top-left,
    &__top-right {
        flex: 1;
    }

    &__top-right {
        text-align: right;

        .ant-btn.ant-btn-link,
        .ant-checkbox-wrapper {
            padding: 0 7px;
        }
    }

    &__dropdown,
    &__datepicker,
    &__input {
        width: 100% !important;
    }

    &__label {
        padding-bottom: 16px;
    }

    &__row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 36px;
        margin-bottom: 24px;

        &--wide {
            grid-template-columns: 1fr;
        }

        &--two {
            grid-template-columns: repeat(2, 1fr);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.is-compact {
        .c-single-next-step-form {
            &__label {
                padding-bottom: 2px;
                color: $grey-7;
            }

            &__row {
                grid-gap: 24px;
                margin-bottom: 12px;
            }
        }
    }
}

.has-errors {
    .ant-select-selection,
    .ant-input {
        border: 1px solid $dull-red;
    }
}
