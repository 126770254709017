@import "@/assets/scss/style.scss";

.chat-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.is-in-project-view {
        min-height: 290px;
        max-height: 620px;

        @extend .o-project-section;

        &::v-deep {
            .chatHeader {
                border-radius: 10px 10px 0 0;
            }

            .ant-empty {
                border-radius: 0 0 10px 10px;
            }
        }
    }
}
