@import "@/assets/scss/style.scss";

.ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $grey-bg;
    margin: 0;
}
