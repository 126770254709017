@import "@/assets/scss/style.scss";

.chat-message-action-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.button {
    margin-right: 8px;
}

