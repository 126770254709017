@import "@/assets/scss/style.scss";

.tab-wrapper {
    padding: 8px 8px 0;
    height: 100%;
    overflow-y: auto;

    &.is-empty {
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
