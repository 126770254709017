@import "@/assets/scss/style.scss";

.chatHeader {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 0 auto;
    background-color: $white-two;
    padding: 12px 24px;
    border-bottom: 1px solid $grey-4;
    min-height: 64px;
}

.innerWrapper {
}

.ant-skeleton {
    &::v-deep {
        .ant-skeleton-content {
            .ant-skeleton-title {
                margin-top: 0;
                margin-bottom: 4px;
            }

            .ant-skeleton-paragraph {
                margin: 0;
            }
        }
    }
}

.right-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.topRow {
    color: $grey-9;
    margin-bottom: 2px;
}

.bottomRow {
    color: $grey-7;
    word-break: break-all;
}
