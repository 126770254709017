@import "@/assets/scss/style.scss";


.wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 16px;
}

.search {
    padding: 0 8px;

    &::v-deep {
        .ant-input {
            box-shadow: none !important;
            border: none !important;
        }
    }
}

